/* menuWords.js
Contains a list of web developer-related words. See words.js for more information. */
export const webdevWords = [
"HTML",
"CSS",
"JavaScript",
"CSS-in-JS",
"minified CSS",
"JSX",
"NPM",
"Yarn",
"Node",
"React",
"TypeScript",
"utility-first",
"Visual Studio Code",
"Jetbrains",
"localhost",
"stylesheet",
"DOM",
"Shadow-DOM",
"transpiler",
"bundler",
"packager",
"state management",
"useState",
"Express web framework",
"Docker container",
"GraphQL",
"WebSockets subscription",
"sockets receiver",
"receive",
"send",
"transmit",
"low-latency",
"fast",
"purge",
"write faster",
"web workers",
"Mozilla",
"compliant",
"hover, focus",
"animation",
"dark mode",
"templating",
"package manager",
"zero-configuration",
"WebAssembly",
"opt-in",
"inline",
"one-command",
"hot reloading",
"ESLint",
"Multer middleware",
"middleware",
"MimeType handling",
"ES6",
"ES6 transpiled",
"Babel",
"Babel handler",
"future-proofing",
"skeleton loader",
"loading spinner",
"icon set",
"margin",
"agile",
"algorithm",
"Bootstrap",
"SASS",
"adaptive design",
"cache",
"deployment",
"full-stack",
"git",
"GitHub",
"GitLab",
"Kubernetes",
"jQuery",
"CoffeeScript",
"minification",
"MySQL",
"PHP",
"MongoDB",
"Postgres database",
"authentication management",
"plugin",
"responsive design",
"Ruby",
"Ruby on Rails",
"sitemap",
"index.html",
"robots.txt",
"Meta-tagging",
"software stack",
"developer",
"ui design",
"ux design",
"web app",
"wireframe",
"wireframe technology",
"progressive web app",
"console",
"compiler",
"automated testing",
"library",
"Bulma",
"CSS framework",
"build scripts",
"automated builds",
"CI/CD",
"cookie",
"cookie compliance",
"local storage",
"performance measurement",
"routing",
"React Router",
"Socket.IO",
"dockerize",
"serverless function",
"SEO-compliant",
"PageSpeed Lighthouse",
"LAMP stack",
"MERN stack",
"MEVN stack",
"PERN stack",
"edge functions",
"automated deployments",
"Jest",
"Flutter",
"UI components",
"modularity",
"pluggable components",
"CORS",
"Express",
"Gulp",
"Redux",
"Virtual-DOM",
"Webpack",
"Yeoman",
"Gatsby",
"rewrite it in Rust",
"compile-time",
"Vite",
"Zepto",
"pragma",
"styled-components",
"PostCSS",
"PurgeCSS",
"Largest Contextful Paint",
"breakpoint",
"SaaS",
"WYSIWYG",
"build flow"
]
