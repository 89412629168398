/* organizationWords.js
Contains a list of organization/VSM-related words. See words.js for more information. */
export const organizationWords = [
    "VSM",
    "maintainability",
    "cybernetic description of the organization",
    "Ashby's law",
    "recursive",
    "algedonic alerts",
    "attenuator",
    "amplifier",
    "Management",
    "System Management",
    "System 1",
    "System 2",
    "System 3",
    "System 4",
    "System 5",
    "viable",
    "structure",
    "metasystems",
    "actuality",
    "capability",
    "potentiality",
    "productivity",
    "latency",
    "performance",
    "Ludwig von Bertalanffy",
    "methodology",
    "framework",
    "empirical",
    "quantification",
    "Interdependence",
    "Input-output",
    "relationships",
    "transformations",
    "negative entropy",
    "positive entropy",
    "goal-seeking",
    "regulation of processes",
    "equilibrium",
    "dynamic homeostasis",
    "differentiation",
    "hierarchy",
    "equifinality",
    "Energy Resources",
    "follow-up",
    "supply chain",
    "customer satisfaction",
    "market needs",
    "customer needs",
    "transcendental",
    "social organization",
    "genetic-societal",
    "open system",
    "open systems",
    "closed systems",
    "cybernetics",
    "George Stibitz",
    "Gottfried Wilhelm von Leibniz",
    "complexity",
    "system analysis",
    "synthesis",
    "philosophy",
    "methodology",
    "technology",
    "analytical view",
    "Churchman",
    "goal",
    "manifest functions",
    "latent functions",
    "control",
    "input",
    "processing",
    "output",
    "GST",
    "Boulding",
    "Industry 1.0",
    "Industry 2.0",
    "Industry 3.0",
    "Industry 4.0",
    "analogies",
    "isomorphisms",
    "adapt",
    "elements",
    "boundaries",
    "supra-systems",
    "sub-systems",
    "energy",
    "homeostatic",
    "flat organization",
    "matrix organization",
    "Society for General Systems Theory",
    "process transformation",
    "Mintzberg",
    "support systems",
    "energy resources",
    "system borders",
    "sub-sub-system",
    "superior system",
    "decomposable",
    "nearly decomposable",
    "non-decomposable",
    "adaptability",
    "synergistic",
    "soft organizations",
    "scanners",
    "floaters",
    "value makers",
    "rapid action developers",
    "partners",
    "operations research",
    "particularized approache",
    "systems approach",
    "system engineering",
    "top-down",
    "bottom-up",
    "holism",
    "regulation control",
    "logistics",
    "Big Data Analysis",
    "Stafford Beer",
    "Norbert Wiener",
    "Warren McCulloch",
    "Ross Ashby",
    "declarative",
    "system",
    "network",
    "communications"
]