/* menuWords.js
Contains a list of lunch menu words. See words.js for more information. */
export const menuWords = ['BBQ-sås',
    'torskburgare', 'tomatiserad',
    'sauce', 'ris', 'vegoburgare',
    'Indian style',
    'mangorayadressing', 'chicken',
    'paj Lorraine', 'krispig',
    'sallad', 'paella', 'fisk',
    'räkor', 'serveras',
    'eget bakat', 'haloumi',
    'bulgursallad',
    'morotstzatziki',
    'Sweet Tuesday', 'lasagne',
    'tomatsås', 'torskrygg',
    'krämig', 'pepparrotssås',
    'potatismos', 'vegolasagne',
    'portergryta', 'kokt',
    'potatis', 'rönnbärsgelé',
    'lax', 'rostad', 'mandel',
    'lök', 'soltorkad',
    'svampsoppa', 'ört', 'olja',
    'vitlöksbröd',
    'Pancake Thursday', 'pannkakor',
    'lättvispad', 'grädde',
    'hemmagjord', 'sylt',
    'köttfärslimpa', 'timjansås',
    'rårörda', 'lingon', 'stilla',
    'havsflundra', 'dillstuvad',
    'hovmästarsås',
    'mannagrynspanerad', 'getost',
    'rödbetor', 'linser',
    'pumpakärnor', 'tikka',
    'masala', 'kall', 'ingefärssås',
    'mangosallad', 'västhavsfisk',
    'citrondressing', 'tagine',
    'gryta', 'kikärtor', 'russin',
    'saffran', 'gräddsås',
    'äppelmos', 'spenatpaj',
    'hälsosallad', 'dumplings',
    'asiatisk', 'råsallad', 'söt',
    'pytt i panna', 'picklade',
    'rödspättarullader', 'svampsås',
    'broccolifritters', 'creme',
    'fraiche', 'korv', 'pasta',
    'gratäng', 'hemgjord',
    'ketchup', 'asiatiska',
    'smaker', 'matig', 'yakiniku',
    'vegan', 'kimchi', 'tacos',
    'salsa', 'pico de gallo',
    'riven', 'ost', 'tortillabröd',
    'guacamole', 'gurka', 'majs',
    'creme fraiche', 'het',
    'fiskgryta', 'hembakat',
    'vegotacos', 'kesoburritos',
    'köttbullar', 'saltgurka',
    'panerad', 'sejfilé',
    'dillmajonnäs', 'rödbetsbiffar',
    'pepparrots-tsatsiki', 'stekt',
    'rustik', 'palsternacksmos',
    'haricots verts',
    'kyckling', 'currysås', 'mango',
    'chutney', 'ugnsbakad', 'dill',
    'senapsmajonnäs',
    'kantarellrisotto', 'smörkokt',
    'bladspenat', 'lammfärsbiffar',
    'rosmarinsås', 'klyftpotatis',
    'gelé', 'remouladsås',
    'tomatsoppa', 'kokosgrädde',
    'hamburgare', 'tillbehör',
    'pommes', 'bouillabaisse',
    'fransk', 'vitvin', 'skaldjur',
    'grönsaker', 'vegburgare',
    'köttfärssås', 'spaghetti',
    'fiskgratäng', 'citron', 'kalv',
    'dillsås', 'picklad',
    'ångbakad', 'skånsk äggkaka',
    'vegansk', 'kåldolmar',
    'kapkummelfilé', 'beurre',
    'blanc', 'fänkålssallad',
    'äpplen', 'västerbottenpaj',
    'kökschefens', 'fläskschnizel',
    'potatisgratäng', 'gröna',
    'ärtor', 'scampiräkor',
    'köksmästarens vis', 'piccata',
    'sötpotatis', 'vispad', 'kiev',
    'fylld', 'persiljekryddat',
    'vitlökssmör',
    'klyftpotatissallad',
    'citronyoghurt',
    'Pierres fisksoppa',
    'gräddstuvad', 'pytt', 'panna',
    'pytt bellman', 'ägg',
    'sparrissås', 'vegobiffar',
    'morot', 'myntatsatsiki',
    'pannbiff', 'löksås',
    'spättafilé', 'vitvinssås',
    'parmesanstekt', 'squash',
    'tomatvinägrett',
    'saffrans-risoni',
    'kycklingschnitzel',
    'sprödbakad',
    'gurkmajonnäsdressing',
    'Pad Thai', 'crispy', 'tofu',
    'thailändska', 'nationalrätten',
    'stroganoff', 'sill', 'brynt',
    'smör', 'makaroni and cheese',
    'hemlagad', 'kalops',
    'toskanska', 'vitlökssås',
    'tandoorigryta',
    'Chili Con Carne', 'gräddfil',
    'skirat', 'tortellini',
    'rödvinsmarinerad', 'rostbiff',
    'kroketter', 'rödvinssås',
    'äggsås', 'ticka naan',
    'Flygande Jacob', 'gräddig',
    'banan', 'thailänska',
    'skaldjursgryta', 'gyllene',
    'chévreost', 'rödbeta',
    'butternut', 'pumpasallad',
    'vitlök', 'permesanbakade',
    'polentssticks', 'biff',
    'wallenbergare', 'torskbiffar',
    'skummig', 'morotsoppa',
    'dragon', 'vitlökskrutonger',
    'sheddafilé', 'japanska',
    'sojasås', 'kebab', 'röd',
    'vit', 'sås', 'pitabröd',
    'kycklinggratäng', 'paprika',
    'champinjoner', 'persilade',
    'dillmajonnäsdressing',
    'rotfruktsgratäng', 'hemgjorda',
    'blomkåls', 'biffar',
    'pepparrots', 'crème', 'boeuf',
    'bourguignon', 'palac',
    'paneer', 'kokosbröd',
    'falukorv', 'gräddstuvade',
    'makaroner', 'cocktailtomat',
    'västerbottenost',
    'gratinerade',
    'champinjonkrepes',
    'köksmästarens', 'kokta',
    'rotfrukter', 'pressgurka',
    'räkgryta indienne',
    'linssoppa', 'mexican',
    'fajitas','tortillasbröd',
    'vegansk', 'sojbits',
    'fisksoppa', 'curry',
    'kycklinglårfilé',
    'Choronsås (tomatiserad bearnaisesås)',
    'laxwallenbergare', 'Tzaispett',
    'heta', 'köttgryta',
    'parmesanstompad',
    'sockerärtor', 'Nasi Goreng',
    'soybits', 'tomat & gurksallad',
    'gratinerad', 'tjockkorv',
    'ketchupsås', 'gräslökssås',
    'frasiga', 'vegbiffar',
    'potatskroketter', 'stuvad',
    'svamp', 'scampi', 'firtti',
    'sötsur', 'portvin', 'fish',
    'chips', 'kryddig', 'korvgryta',
    'chorizo', 'rödspätta',
    'vitlöksdressing', 'grekisk',
    'fetaost', 'oliver', 'bites',
    'MSC', 'mangoraya', 'dressing',
    'panpizza', 'fungi',
    'pizzasallad', 'kycklinggryta',
    'couscous', 'pepparrot',
    'pepparrots-tzatziki',
    'laxfjäril', 'wokade', 'nudlar',
    'brunsås', 'kålrot', 'senap',
    'spenat', 'kycklingnuggets',
    'citronmajonnässås',
    'Jambalaja', 'aioli',
    'ugnsrostad', 'champinjonsås'
]